export default {
  actions: {
    label: 'Action',
    disabledActionLabel: 'You cannot perform actions in an unfollowed position.',
    disabledActionLabelDeactivated: 'The candidate has deactivated the appyHere account',
    disabledActionLabelNotLookingForWork: 'This candidate is not currently looking for work and cannot be contacted.',
    meet: {
      label: 'Meet',
      confirmationModal: {
        header: '{{duration}} min. {{type, upperFirst}} Interview Request',
        body: 'The candidate will see a selection of available time slots for an interview.',
        cancelButton: 'Cancel',
        sendButton: 'Send',
      },
      notificationModal: {
        header: 'No Recruiter Interview Availability',
        body:
          'To invite a candidate for an interview at least one recruiter in your organization needs availability in the AppyHere calendar.',
        cancelButton: 'Cancel',
        createButton: 'Add Availability',
      },
    },
    dismiss: {
      label: 'Dismiss',
      confirmationModal: {
        header: 'Dismiss This Candidate?',
        body: 'The candidate will not be considered for this position.',
        dismissalReasonEnabledBody:
          'This candidate will not be considered for this position. Please select a reason for their dismissal:',
        cancelButton: 'Cancel',
        sendButton: 'Confirm',
        others: 'Other reason(s)',
        errorMsg: 'You must select dismissal reason.',
        reasonForDismissal: 'Specify the reason(s) for dismissal',
      },
    },
    undismiss: {
      label: 'Reverse the dismissal for this candidate.',
      confirmationModal: {
        header: `Reverse This Candidate's Dismissal`,
        body: 'The candidate will no longer be considered dismissed, and will be returned to the active candidate list.',
        cancelButton: 'Cancel',
        sendButton: 'Confirm',
      },
    },
    unDismissButton: 'Undo Dismissal',
    backgroundCheck: {
      label: 'Background Check',
      confirmationModal: {
        header: 'Contact us to add background checks!',
        body: 'For more information on how to add background checks to your account, you can email us directly.',
        sendButton: 'Ok',
      },
    },
    hire: {
      startDate: 'Start date',
      modalMessage: 'Note',
      hireDetailsDesc1: 'The candidate was hired.',
      dateOfJoining: 'Candidate’s earliest availability',
      hireOrReject: 'Hire / Reject',
      unhire: 'Unhire',
      offerMade: 'Make an Offer',
      reoffer: 'Send New Offer',
      label: 'Hire',
      sendMessageToCandidates: 'Send offer to the candidate',
      sendMessageToCandidatesToolTip:
        'Checking this box will send the offer message to the candidate, allowing the candidate to update their earliest available date if needed.',
      autoDismissCandidates: 'Automatically dismiss the candidate if offer is rejected.',
      autoDismissCandidatesToolTip: 'Checking this box will dismiss the candidate if they reject the offer.',
      confirmationModal: {
        salary: 'Salary',
        hourly: 'Hourly',
        annually: 'Annual',
        annual: 'Annually',
        custom: 'Custom',
        salaryValue: 'Salary Value',
        customUnit: 'Custom Unit',
        header: 'Enter salary details about the offer',
        body: 'Add Details',
        cancelButton: 'Cancel',
        sendButton: 'Submit',
        saveButton: 'Save',
        text1: `Initial Proposed Salary is `,
        text2: `which is to be paid`,
        candidateHiringForm: 'Candidate Hiring Form',
        selectHireFormType: 'Select Hire Form Type',
        hireFormType: 'Hire Form Type',
        contractStartDate: `Candidate’s earliest availability`,
        contractEndDate: `Contract End Date`,
        contractDetailsWording: `Terms`,
        contractDetailsWordingTooltip: `These terms will be included on the form sent to the candidate.`,
        contractWordingHintMessage: `As the candidate is using the french language in the app.`,
        contractTerms: ``,
        contractDetailsWordingPlaceholder: `Enter contract description`,
        candidateStatus: `Candidate's Status`,
        partTime: 'Part-time',
        fullTime: 'Full-time',
        storeNumber: 'Store Number',
        unitRequired: 'Unit is required',
        valueRequired: 'Value is required',
        noteRequired: 'Note is required',
        positionNameRequired: 'Position Name is required',
        businessNameRequired: 'Business Name is required',
        candidateStatusRequired: `Candidates status is required`,
        contractStartDateRequired: 'Earliest start date is required',
        contractEndDateRequired: 'Contract end date is required',
        contractDetailsWordingRequired: 'Contract details wording is required',
        startDateRequired: 'Earliest start date is required',
        storeNumberRequired: 'Store number is required',
        formTypeRequired: 'Form type is required',
        startNewHire: 'New hire',
        startWorkPermit: 'New hire for candidate with work permit',
        startSeasonalContractor: 'New hire for seasonal candidate',
        submit: 'Submit',
        workPermitEndDate: 'Work Permit End Date',
        country: 'Country',
        partTimeHoursPerWeek: 'Hours/Week',
      },
      messageAfterHiringModal: {
        headerText: 'AppyHere will send notification to seekers informing that they are hired.',
        modalText: 'Candidates will be inactive in this platform and hence will be unavailable for other jobs.',
        dismissText: 'Never see this message again',
        buttonText: 'Ok',
      },
      rejectModal: {
        unhireCandidate: 'Unhire Candidate',
        reasonForChange: 'Write a reason for change',
        header: 'Hire Candidate',
        body: 'Has the candidate been hired?',
        hired: 'Start Hiring',
        rejected: 'Offer Rejected',
        seenToCandidatePlaceholder: 'Information entered here will not been seen by candidate',
        placeholder: 'Add Your Feedback',
      },
      actionsAfterUnhire: {
        removeForever: 'Remove Forever',
        hireInFuture: 'I may want to hire this candidate in the future',
        hireHimAgain: 'Might you hire this candidate again?',
        headerText: 'Unhiring a Candidate',
      },
    },
    soon: 'soon',
  },
  candidateLocked: 'Message from candidate. Unlock it in the mobile app.',
  interviewRating: {
    title: 'Candidate Assessment',
    subTitle: `How did the interview with the candidate go?`,
    lowReason: 'Select a reason for the low rating:',
    commentPlaceholder: 'Leave a comment',
    successful: 'Rating successful',
    feedback: 'Candidate Assessment',
    unsuccessful: 'Candidate must have an appointment to be able to send an assessment',
    dateAndTimeOFInterview: 'Date & Time of Interview',
    interviewType: 'Interview Type',
    feedbackDetail: 'How did the interview with the candidate go? ',
    recruitersFeedback: 'Comments ',
  },
  chatInput: {
    deactivatedUserLabel: 'The candidate must have had an interview before they can be assessed.',
  },
  headerWhenRequisitionEnabled: 'Offer Made',
  offerMadewarning: {
    title: 'Candidate Hires and Offers',
    subtitle: 'This candidate has already been hired on a position, or has received other offers.',
    continueAnyWay: 'Send anyway',
    position: 'Position',
    recruiter: 'Recruiter',
    date: 'Offer Date',
    offerStatus: 'Offer Status',
    currentStatus: 'Candidate Status',
    offerHistory: 'Offer & Hire History',
    hireThroughAPI: 'Virtual Recruiter',
  },
};
