import React, { useCallback } from 'react';
import { Box, Image, Text, Center } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../app/topBar/Header';
import { useStoreActions, useStoreState } from '../../models/hooks';

export const PaymentSuccessfulView = (): JSX.Element => {
  const { t } = useTranslation('tokenPayment');

  const accountId = useStoreState((s) => s.app.accounts?.id);
  const { getReceipt } = useStoreActions((actions) => actions.product);

  // function that fetches tokens/products
  const handleReceipt = useCallback(
    async (id: string) => {
      if (accountId) {
        const receiptResponse = await getReceipt({ accountId: id });

        if (receiptResponse.transactionReceipt && receiptResponse.transactionReceipt !== undefined) {
          window.open(receiptResponse.transactionReceipt, '_blank');
        } else {
          console.error('No receipt url found');
        }
      }
    },
    [getReceipt, accountId],
  );

  return (
    <React.Fragment>
      <Header showMenu />
      <Box display="flex" flexDirection="column" justifyContent="center" height="100vh">
        <Center>
          <Image src="/successful.png" alt="success-image" height={{ base: '150px', md: '200px', lg: '250px' }} />
        </Center>
        <Center py={5}>
          <Text fontSize="24px" fontWeight={400} color="#A0AEC0" data-testid="payment-success">
            {t('paymentSuccessful')}
          </Text>
        </Center>
        <Center py={3}>
          <Text fontSize="24px" fontWeight={700}>
            {t('congrats')}
          </Text>
        </Center>
        <Center flexDirection="row" justifyContent="center" alignItems="center">
          <Text fontSize="16px" fontWeight={400}>
            {t('orderDetails')}
          </Text>
          <Box
            cursor="pointer"
            data-testid="clickHere"
            onClick={() => {
              if (accountId) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleReceipt(accountId);
              }
            }}
          >
            <Text fontSize="16px" fontWeight={700} paddingLeft="5px" textDecoration="underline">
              {t('here')}
            </Text>
          </Box>
        </Center>
        <Center paddingTop="120px" cursor="pointer">
          <Link to="/dashboard">
            <Text fontSize="20px" fontWeight={400} color="blue.500" data-testid="dashboard">
              {t('goHome')}
            </Text>
          </Link>
        </Center>
      </Box>
    </React.Fragment>
  );
};
