/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Tooltip,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Flex,
} from '@chakra-ui/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useState } from 'react';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosStar,
  IoMdCalendar,
  IoMdCheckboxOutline,
  IoMdRemoveCircleOutline,
  IoMdMore,
} from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { RiErrorWarningLine } from 'react-icons/ri';
import moment from 'moment';
import { menuButtonCSS, menuListCSS } from '../SeekerHeader.styles';
import colors from '../../../../../styles/colors';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import {
  doesCandidateHasActiveAppointment,
  isCandidateAlreadyDismissed,
  isCandidateAlreadyHired,
  isCandidateReadOnly,
  isCandidateFromFollowedPosition,
  isCandidateHireInReview,
} from '../../../../../services/CandidateService';
import { MeetConfirmationModal } from './MeetConfirmationModal';
import { HireConfirmationModal } from './HireConfirmationModal';
import { UnhireConfirmationModal } from './UnhireConfirmationModal';
import { DismissConfirmationModal } from './DismissConfirmationModal';
import { SubscribeForBackgroundCheckModal } from './SubscribeForBackgroundCheckModal';
import { BackgroundCheckModal } from './BackgroundCheckModal';
import { CandidateStatus } from '../../../../../firebase/firestore/documents/candidate';
import { usePositionBackgroundCheckSnapshot } from './usePositionBackgroundCheckSnapshot';
import { MessageAfterHiringModal } from './MessageAfterHiringModal';
import { useSeekersFilters } from '../../../sidebar/filters/SeekersFiltersContext';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import { UnDismissConfirmationModal } from './UnDismissConfirmationModal';
import { HireRejectConfirmationModal } from './HireRejectConfirmationModal';
import { useStoreState } from '../../../../../models/hooks';
import { HireWithRequisitionModal } from './HireWithRequisitionModal';
import { useMessagingViewContext } from '../../../MessagingViewContext';
import { OfferMadePositionsView } from './OfferMadePositionsView';
import useDaysUptoCandidateInteractAfterHire from '../../../../../app/hooks/useDaysUptoCandidateInteractAfterHire';

export type ActionsDropdownProps = {
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
  certnStatus?: string;
  isUserDeactivated?: boolean;
  isUndismissedeEnabled: boolean;
  isMessagingView: boolean;
};

export const ActionsDropdown = ({
  isCertnFeatureEnabled,
  isCertnEnabled,
  certnStatus,
  isUserDeactivated,
  isUndismissedeEnabled,
  isMessagingView,
}: ActionsDropdownProps): JSX.Element => {
  const { t } = useTranslation('messaging');
  const { selectedCandidate } = useSeekerContainerContext();
  const {
    isHireModalOpen,
    onHireModalOpen,
    onHireModalClose,
    req,
    isMeetModalOpen,
    onMeetModalOpen,
    onConfirmModalClose,
    selectedSeeker,
    selectedEngagedCandidate,
  } = useMessagingViewContext();
  const candidate = selectedCandidate || selectedEngagedCandidate;

  const positionScreeningOption = usePositionBackgroundCheckSnapshot(candidate?.position);
  const { warningMessage } = useSeekersFilters();
  const { currentUserProfile } = useCurrentUserProfile();

  const { isOpen: isDismissModalOpen, onOpen: onDismissModalOpen, onClose: onDismissModalClose } = useDisclosure();
  const { isOpen: isHireRejectModalOpen, onOpen: onHireRejectModalOpen, onClose: onHireRejectModalClose } = useDisclosure();

  const { isOpen: isUnhireModalOpen, onOpen: onUnhireModalOpen, onClose: onUnhireModalClose } = useDisclosure();
  const { isOpen: isOpenWarning, onOpen: onOpenWarning, onClose: onCloseWarning } = useDisclosure();

  const cancelRef = React.useRef(null);

  const {
    isOpen: isSubscribeForBackgroundCheckModalOpen,
    onOpen: onSubscribeForBackgroundCheckModalOpen,
    onClose: onSubscribeForBackgroundCheckModalClose,
  } = useDisclosure();
  const {
    isOpen: isBackgroundCheckModalOpen,
    onOpen: onBackgroundCheckModalOpen,
    onClose: onBackgroundCheckModalClose,
  } = useDisclosure();
  /* istanbul ignore next */
  const requisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);
  /* istanbul ignore next */
  const candidateReofferEnabled = useStoreState((state) => state.app.accounts?.configuration?.candidateReofferEnabled);

  const isCurrentCandiateNotFollowed = candidate ? !isCandidateFromFollowedPosition(candidate, currentUserProfile?.id) : false;

  const {
    isOpen: isMessageAferHiringModalOpen,
    onOpen: onMessageAferHiringModalOpen,
    onClose: onMessageAferHiringModalClose,
  } = useDisclosure();

  const hireActionDisabled = candidate
    ? isCandidateAlreadyHired(candidate) ||
      isCandidateAlreadyDismissed(candidate) ||
      isCandidateReadOnly(candidate) ||
      isCandidateHireInReview(candidate.computedStatus?.status)
    : true;
  const meetActionDisabled = candidate
    ? doesCandidateHasActiveAppointment(candidate) ||
      isCandidateAlreadyDismissed(candidate) ||
      isCandidateAlreadyHired(candidate) ||
      isCandidateReadOnly(candidate) ||
      isCandidateHireInReview(candidate.computedStatus?.status)
    : true;
  const dismissActionDisabled = candidate
    ? isCandidateAlreadyDismissed(candidate) || isCandidateAlreadyHired(candidate) || isCandidateReadOnly(candidate)
    : true;

  const { isOpen: isUnDismissModalOpen, onOpen: onUnDismissModalOpen, onClose: onUnDismissModalClose } = useDisclosure();

  const periodWhichHiredCandidateInteract = useDaysUptoCandidateInteractAfterHire();
  const addedHiredDate = moment(candidate?.hiredAt).add(periodWhichHiredCandidateInteract || 10, 'days');
  const isHiredCandidateEligibleToInteract = moment(moment()).isBefore(addedHiredDate);

  /* istanbul ignore next */
  const isCandidateEligibleToInteract =
    candidate?.seekerLookingForWork || (candidate?.hired && isHiredCandidateEligibleToInteract);

  const showCertnBackgroundCheckMenu = () => {
    return (
      isCertnFeatureEnabled && (
        <MenuItem
          data-testid="BackgroundCheckDropdownTestId"
          id="MeetMenuItem"
          minH="48px"
          _focus={{ bg: colors.blue['700'] }}
          disabled={
            (!(certnStatus === 'dispute' || certnStatus === 'complete' || certnStatus === undefined) && isCertnEnabled) ||
            (!_isNil(positionScreeningOption) && !positionScreeningOption.enableScreeningOptions) ||
            isUserDeactivated ||
            isCurrentCandiateNotFollowed
          }
          onClick={isCertnEnabled ? onBackgroundCheckModalOpen : onSubscribeForBackgroundCheckModalOpen}
          color={isCertnEnabled ? '#ffffff' : '#9FC5D9'}
        >
          <Tooltip
            label={
              !_isNil(positionScreeningOption) && !positionScreeningOption.enableScreeningOptions
                ? t('certn:messageNotEnabledOnPositionLevel')
                : ''
            }
            aria-label={
              !_isNil(positionScreeningOption) && !positionScreeningOption.enableScreeningOptions
                ? t('certn:messageNotEnabledOnPositionLevel')
                : ''
            }
            placement="top-start"
          >
            <Flex alignItems="center">
              <Box pr="12px">
                <IoMdCheckboxOutline />
              </Box>
              <Box>
                <Text>{t('certn:bgCheckLabel')}</Text>
              </Box>
            </Flex>
          </Tooltip>
        </MenuItem>
      )
    );
  };

  const hireActionHandler = (status: CandidateStatus | undefined) => {
    let hireMenu;
    if (status === CandidateStatus.AppointmentRequested) {
      hireMenu = (
        <Fragment>
          <MenuItem
            id="HireMenuItem"
            minH="48px"
            _focus={{ bg: colors.blue['700'] }}
            disabled={
              selectedEngagedCandidate?.offerAcceptanceStatus?.offerAccepted || isUserDeactivated || isCurrentCandiateNotFollowed
            }
            data-testid="AppointmentRequestedAction"
            onClick={() =>
              selectedEngagedCandidate && selectedSeeker?.offeredOrHiredAccounts?.includes(selectedEngagedCandidate.account)
                ? onOpenWarning()
                : onHireModalOpen()
            }
          >
            <Box pr="12px">
              <IoIosStar />
            </Box>
            {t('messaging:actions.hire.offerMade')}
          </MenuItem>
          {showCertnBackgroundCheckMenu()}
        </Fragment>
      );
    } else if (status === CandidateStatus.OfferMade) {
      hireMenu = (
        <Fragment>
          {showCertnBackgroundCheckMenu()}
          {!selectedEngagedCandidate?.offerAcceptanceStatus?.offerAccepted && (
            <MenuItem
              id="HireMenuItem"
              minH="48px"
              _focus={{ bg: colors.blue['700'] }}
              data-testid="AppointmentRequestedAction"
              disabled={isUserDeactivated || isCurrentCandiateNotFollowed}
              onClick={() =>
                selectedEngagedCandidate && selectedSeeker?.offeredOrHiredAccounts?.includes(selectedEngagedCandidate.account)
                  ? onOpenWarning()
                  : onHireModalOpen()
              }
            >
              <Box pr="12px">
                <IoIosStar />
              </Box>
              {t('messaging:actions.hire.offerMade')}
            </MenuItem>
          )}

          <MenuItem
            id="HireMenuItem"
            minH="48px"
            _focus={{ bg: colors.blue['700'] }}
            data-testid="OfferMadeAction"
            disabled={isUserDeactivated || isCurrentCandiateNotFollowed}
            onClick={onHireRejectModalOpen}
          >
            <Box pr="12px">
              <IoIosStar />
            </Box>
            {t('messaging:actions.hire.hireOrReject')}
          </MenuItem>
        </Fragment>
      );
    } else if (status === CandidateStatus.Hired) {
      hireMenu = (
        <Fragment>
          <MenuItem
            id="HireMenuItem"
            minH="48px"
            _focus={{ bg: colors.blue['700'] }}
            data-testid="UnHireAction"
            onClick={onUnhireModalOpen}
            disabled={isUserDeactivated}
          >
            <Box pr="12px">
              <IoIosStar />
            </Box>
            {t('messaging:actions.hire.unhire')}
          </MenuItem>
          {showCertnBackgroundCheckMenu()}
        </Fragment>
      );
    } else if (status === CandidateStatus.OfferRejected) {
      hireMenu = (
        <Fragment>
          {showCertnBackgroundCheckMenu()}
          {candidateReofferEnabled && (
            /* istanbul ignore next */
            <MenuItem
              id="OfferMadeItem"
              minH="48px"
              _focus={{ bg: colors.blue['700'] }}
              data-testid="OfferRejectedAction"
              onClick={
                /* istanbul ignore next */ () =>
                  selectedEngagedCandidate && selectedSeeker?.offeredOrHiredAccounts?.includes(selectedEngagedCandidate.account)
                    ? onOpenWarning()
                    : onHireModalOpen()
              }
              disabled={/* istanbul ignore next */ isUserDeactivated || hireActionDisabled}
            >
              <Box pr="12px">
                <IoIosStar />
              </Box>
              {t('messaging:actions.hire.reoffer')}
            </MenuItem>
          )}
          <MenuItem
            id="HireMenuItem"
            minH="48px"
            _focus={{ bg: colors.blue['700'] }}
            data-testid="OfferRejectedAction"
            onClick={onHireRejectModalOpen}
            disabled={isUserDeactivated}
          >
            <Box pr="12px">
              <IoIosStar />
            </Box>
            {t('messaging:actions.hire.hireOrReject')}
          </MenuItem>
        </Fragment>
      );
    } else {
      hireMenu = (
        <Fragment>
          <MenuItem
            id="OfferMadeItem"
            minH="48px"
            _focus={{ bg: colors.blue['700'] }}
            data-testid="OfferMadeDisabledAction"
            onClick={() =>
              selectedEngagedCandidate && selectedSeeker?.offeredOrHiredAccounts?.includes(selectedEngagedCandidate.account)
                ? onOpenWarning()
                : onHireModalOpen()
            }
            disabled={hireActionDisabled || selectedEngagedCandidate?.offerAcceptanceStatus?.offerAccepted || isUserDeactivated}
          >
            <Box pr="12px">
              <IoIosStar />
            </Box>
            {t('messaging:actions.hire.offerMade')}
          </MenuItem>
          {showCertnBackgroundCheckMenu()}
        </Fragment>
      );
    }

    return hireMenu;
  };

  const getTolTipForAction = () => {
    if (isUserDeactivated) {
      return t('messaging:actions.disabledActionLabelDeactivated');
    }
    if (isCurrentCandiateNotFollowed) {
      return t('messaging:actions.disabledActionLabel');
    }
    if (!isCandidateEligibleToInteract) {
      return t('messaging:actions.disabledActionLabelNotLookingForWork');
    }
    return '';
  };

  return (
    <Fragment>
      {isMessageAferHiringModalOpen && (
        <MessageAfterHiringModal isOpen={isMessageAferHiringModalOpen} onClose={onMessageAferHiringModalClose} />
      )}
      {isUnhireModalOpen && (
        <UnhireConfirmationModal
          isOpen={isUnhireModalOpen}
          disabled={false}
          onClose={onUnhireModalClose}
          isMessagingView={isMessagingView}
        />
      )}
      {isHireRejectModalOpen && (
        <HireRejectConfirmationModal
          isOpen={isHireRejectModalOpen}
          warningMessage={warningMessage}
          messageModalOpen={onMessageAferHiringModalOpen}
          disabled={false}
          onClose={onHireRejectModalClose}
        />
      )}
      {isHireModalOpen && (
        <Box>
          {requisitionEnabled ? (
            /* istanbul ignore next */
            <HireWithRequisitionModal
              isOpen={isHireModalOpen}
              disabled={false}
              onClose={onHireModalClose}
              requisitionEnabled={requisitionEnabled}
              selectedRequisitionID={req}
            />
          ) : (
            <HireConfirmationModal
              positionSalaryDetails={positionScreeningOption?.proposedSalary}
              isOpen={isHireModalOpen}
              disabled={false}
              onClose={onHireModalClose}
              requisitionEnabled={requisitionEnabled}
            />
          )}
        </Box>
      )}
      {isMeetModalOpen && (
        <MeetConfirmationModal
          isOpen={isMeetModalOpen}
          disabled={isMessagingView ? meetActionDisabled : false}
          onClose={onConfirmModalClose}
          isMessagingView={isMessagingView}
        />
      )}
      {isDismissModalOpen && (
        <DismissConfirmationModal
          isOpen={isDismissModalOpen}
          disabled={dismissActionDisabled}
          onClose={onDismissModalClose}
          isMessagingView={isMessagingView}
        />
      )}
      {isSubscribeForBackgroundCheckModalOpen && (
        /* istanbul ignore next */ <SubscribeForBackgroundCheckModal
          isOpen={isSubscribeForBackgroundCheckModalOpen}
          onClose={onSubscribeForBackgroundCheckModalClose}
        />
      )}
      {isBackgroundCheckModalOpen && (
        <BackgroundCheckModal isOpen={isBackgroundCheckModalOpen} onClose={onBackgroundCheckModalClose} />
      )}

      {candidate && isCandidateAlreadyDismissed(candidate) && isUndismissedeEnabled ? (
        <Button
          css={menuButtonCSS}
          onClick={() => onUnDismissModalOpen()}
          data-testid="unDismissedButton"
          fontWeight="400"
          _hover={{ bg: colors.neutralGray['100'] }}
        >
          {' '}
          {t('messaging:actions.unDismissButton')}
        </Button>
      ) : (
        <Menu id="ActionsDropdownMenu" placement="bottom-end">
          {({ isOpen }) => (
            <Fragment>
              <Tooltip hasArrow label={getTolTipForAction()} shouldWrapChildren placement="bottom-end" gutter={25}>
                <MenuButton
                  _disabled={{
                    opacity: 0.5,
                    cursor: 'not-allowed',
                    boxShadow: 'none',
                  }}
                  css={menuButtonCSS}
                  className={isOpen ? 'is-opened' : ''}
                  px={{ lg: 4, md: 2, sm: 2 }}
                  py={2}
                  rounded="lg"
                  data-testid="DropDownActions"
                  disabled={isUserDeactivated || !isCandidateEligibleToInteract}
                >
                  <Box display={{ lg: 'flex', md: 'none', sm: 'none' }} alignItems="center" placement="bottom-end">
                    {t('messaging:actions.label')}
                    <Box ml={2} marginRight="-6px">
                      {isOpen ? <IoIosArrowUp size={16} /> : <IoIosArrowDown size={16} />}
                    </Box>
                  </Box>
                  <Box display={{ lg: 'none', md: 'block' }}>
                    <IoMdMore size={22} />
                  </Box>
                </MenuButton>
              </Tooltip>
              <MenuList bg={colors.blue['500']} color={colors.white} css={menuListCSS}>
                <MenuItem
                  id="MeetMenuItem"
                  minH="48px"
                  _focus={{ bg: colors.blue['700'] }}
                  onClick={onMeetModalOpen}
                  disabled={meetActionDisabled || isUserDeactivated || isCurrentCandiateNotFollowed}
                  data-testid="MeetMenuItem"
                >
                  <Box pr="12px">
                    <IoMdCalendar />
                  </Box>
                  {t('messaging:actions.meet.label')}
                </MenuItem>
                {hireActionHandler(selectedCandidate?.computedStatus?.status)}
                <MenuItem
                  id="DismissMenuItem"
                  data-testid="DismissMenuItem"
                  minH="48px"
                  _focus={{ bg: colors.blue['700'] }}
                  onClick={onDismissModalOpen}
                  disabled={dismissActionDisabled}
                >
                  <Box pr="12px">
                    <IoMdRemoveCircleOutline />
                  </Box>
                  {t('messaging:actions.dismiss.label')}
                </MenuItem>
              </MenuList>
            </Fragment>
          )}
        </Menu>
      )}
      {onUnDismissModalOpen && (
        <UnDismissConfirmationModal
          isOpen={isUnDismissModalOpen}
          onClose={onUnDismissModalClose}
          isMessagingView={isMessagingView}
        />
      )}
      {isOpenWarning && (
        <AlertDialog
          isOpen={isOpenWarning}
          leastDestructiveRef={cancelRef}
          onClose={onCloseWarning}
          isCentered
          size="5xl"
          scrollBehavior="inside"
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontWeight="bold" fontSize="22px" pb="0px">
                <Box>{t('messaging:offerMadewarning.title')}</Box>

                <Flex background="#FFFFF0" color="#B7791F" borderLeft="6px solid #FFB323" p="10px" alignItems="center" mt="10px">
                  <RiErrorWarningLine size="30px" color="#FFB323" />
                  <Text ml="10px" fontSize="16px">
                    {t('messaging:offerMadewarning.subtitle')}
                  </Text>
                </Flex>
                <Text fontWeight="900" fontSize="20px" color={colors.blue.default} mt="12px">
                  {t('messaging:offerMadewarning.offerHistory')}
                </Text>
              </AlertDialogHeader>

              <AlertDialogBody>
                <OfferMadePositionsView />
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onCloseWarning} colorScheme="red">
                  {t('messaging:actions.meet.confirmationModal.cancelButton')}
                </Button>
                <Button
                  ref={cancelRef}
                  data-testid="onAcceptButton"
                  colorScheme="blue"
                  onClick={
                    /* istanbul ignore next */ () => {
                      onCloseWarning();
                      onHireModalOpen();
                    }
                  }
                  ml={3}
                >
                  {t('messaging:offerMadewarning.continueAnyWay')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </Fragment>
  );
};
